import client from "../../client";
import siteOptions from "../../../siteconfigs/index";
import urlHelper from "../../../utils/urlHelper.js";

const Api = {
  getList: "/api/ProductService/MonthAccountBill/GetListPaged",
  getBillDetails: "/api/ProductService/MonthAccountBill/GetByIdWithDetails",

  downloadBill: "/api/ProductService/CostLogItem/DownloadBill",

  getPrintUrl:"/api/ProductService/MonthAccountBill/GetOrCreateMonthBillPdf"
};

export const getList = ({ skipCount, maxResultCount, sorting }) => {
  return client.request({
    url: Api.getList,
    data: { skipCount, maxResultCount, sorting },
    method: "post",
  });
};

export const getBillDetails = (id) => {
  return client.request({
    url: Api.getBillDetails,
    params: { id },
    method: "get",
  });
};

export const downloadBill = (billId) => {
  return client.request({
    url: Api.downloadBill,
    params: { billId },
    method: "get",
  });
};

export const getDownloadBillUrl = (billId)=>{
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.downloadBill);
  url = urlHelper.addQueryString(url, "billId", billId);
  return url;
}


export const getPrintUrl = (monthAccountBillId) => {
  return client.request({
    url: Api.getPrintUrl,
    params: { monthAccountBillId },
    method: "get",
  });
};